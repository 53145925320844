import React from "react";
import { withRouter } from "react-router";

import logo from "../res/sanifer-white-logo.png"

import Menu from "./drawers/Menu";

import Cart from "./drawers/Cart";

import $ from "jquery";



class Navbar extends React.Component {
    _mounted;

    constructor(props){
        super(props);

        this.state = {
            menu:false,
            cart:false,
            search:""
        }
    }

    componentDidMount(){
        this._mounted = true;

        $(".special-search").on('keypress',(e) =>{
            if(e.which == 13) {
                this.lookfor();
            }
        });
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    lookfor =() => {
        if(this.state.search.trim() !== ""){
            this.props.history.push(`/busqueda/${String(this.state.search).toLowerCase()}`)
        }else {
            this.props.addToast("Ingresa texto en el buscador")
        }
    }

    render(){
        return <>
        {this.state.cart?<Cart auth={this.props.auth} setQuantity={this.props.setQuantity} cleanCart={this.props.cleanCart} close={() => {
                if(this._mounted){
                    this.setState({
                        cart:false
                    })
                }
            }} addQuantity={this.props.addQuantity} userData={this.props.userData} removeItem={this.props.removeItem} removeQuantity={this.props.removeQuantity} addToast={this.props.addToast} cart={this.props.cart}/>:null}
            {this.state.menu?<Menu auth={this.props.auth} close={() => {
                if(this._mounted){
                    this.setState({
                        menu:false
                    })
                }}}/>:null}
        <div className="nav-bar sticky-top">
            
            <div className="flex-sb">
            <div className="flex-left">
                <button className="btn menu" onClick={() => {
                    if(this._mounted){
                        this.setState({
                            menu:true
                        })
                    }
                }}>
                    <i className="material-icons align-middle">menu</i>
                </button>

                <div className="logo" onClick={() => {
                    this.props.history.push("/inicio")
                }}>
                    <img src={logo}/>
                </div>
            </div>

            <div>
                <button className="btn cart" onClick={() => {
                    if(this.props.auth){
                    if(this._mounted){
                        this.setState({
                            cart:true
                        })
                    }
                }else {
                    this.props.history.push("/ingreso");
                    this.props.addToast("Debe iniciar sesión")
                }
                }}>
                    <i className="material-icons align-middle">shopping_cart</i>

                  {this.props.cart.length > 0?<div className="indicator"><p className="mb-0">{this.props.cart.length}</p></div>:null}
                </button>
            </div>
            </div>

            <div className="input-group mt-3 shadow-sm">
                <div className="input-group-prepend">
                  <span className="input-group-text p-0">
                    <button className="btn py-1" onClick={() => {
                        this.lookfor();
                    }}>
                        <i className="material-icons align-middle">search</i>
                    </button>
               </span>
                </div>

                <input className="form-control special-search" value={this.state.search} onChange={e => {
                    if(this._mounted){
                        this.setState({
                            search:e.currentTarget.value
                        })
                    }
                }} placeholder="Buscador"/>
            </div>
        </div>
        </>
    }
}

export default withRouter(Navbar);