import logo from './logo.svg';
import './styles/sass/App.scss';
import React from "react";

import {BrowserRouter as Router, Switch, NavLink, Route, Redirect} from "react-router-dom";
import { Position, Toast, Toaster, Classes, Alert, Intent} from "@blueprintjs/core";
import { lazy, Suspense } from 'react';

import CircleLoader from "./components/loaders/CircleLoader";

import Navbar from "./components/Navbar";

import firebase from "./utils/firebaseSetUp";

import ModalProduct from "./components/modals/ModalProduct";


const Auth = lazy(() => import("./components/Auth"));
const Home = lazy(() => import("./components/Home"));
const SearchModule = lazy(() => import("./components/SearchModule"));
const Favorites =lazy(() => import("./components/Favorites"));
const Products = lazy(() => import("./components/Products"));


class App extends React.Component {
  _mounted;
  _listenFavorites;
  _userData;

  constructor(props){
   super(props);

   this.state = {
     auth:false,
     toasts:[],
     userData:null,
     cart:[],
     productData:null,
     modalProduct:false,
     favorites:[]
   }

   this.toaster = {};
   this.refHandlers = {
      toaster:(ref) => {this.toaster = ref},
    }
  }

  addToast = (message) => {
    this.toaster.show({ message: message});
}

  componentDidMount(){
    this._mounted = true;

    firebase.auth().onAuthStateChanged(u => {
      if(u){

        firebase.firestore().collection("usuariosClientes").doc(u.uid).get()
        .then(doc => {

          if(doc.exists){
          let data = doc.data();
          data.id = doc.id;

          if(doc.data().approvalState=== "pending"){
            this.addToast("Su perfil está pendiente de aprobación");

            setTimeout(() => {
              firebase.auth().signOut();
            },3000)
            
          }else {

          this.mountFavorites(data);
          this.mountUserData(data)

          if(this._mounted){
            this.setState({
              auth:true,
              userData:data
            })
          }
        }
        }else {
          setTimeout(() => {
            firebase.firestore().collection("usuariosClientes").doc(u.uid).get()
        .then(doc => {

          if(doc.exists){
          let data = doc.data();
          data.id = doc.id;

          if(doc.data().approvalState=== "pending"){
            this.addToast("Su perfil está pendiente de aprobación");

            setTimeout(() => {
              firebase.auth().signOut();
            },3000)
          }else {

          this.mountFavorites(data);
          this.mountUserData(data)

          if(this._mounted){
            this.setState({
              auth:true,
              userData:data
            })
          }

        }
        }

        })
        .catch(e => {
          console.log(e);
          this.addToast("Algo salió mal");
        })
        },2000)


        }
        })
        .catch(e => {
          console.log(e);
          this.addToast("Algo salió mal");
        })
      }else {

        if(this._listenFavorites !== undefined){
          this._listenFavorites()//Clean up the listener
        }

        if(this._userData !== undefined){
          this._userData() //Clean up the listener
        }
        if(this._mounted){
          this.setState({
            auth:false,
            userData:null,
            cart:[]
          })
        }
      }
    })
  }

  componentWillUnmount(){
    this._mounted = false;
  }


  mountUserData = (userData) => {
    if(this._userData !== undefined){
      this._userData()//Clean up the listener
    }

    firebase.firestore().collection("usuariosClientes").doc(userData.id).onSnapshot(doc => {
      if(doc.exists){
        let data =doc.data();
        data.id = doc.id;


        if(this._mounted){
          this.setState({
            userData:data
          })
        }
      }
    }, e => {
      console.log(e);
      this.addToast("Algo salió mal")
    })
  }

  mountFavorites = (userData) => {
    if(this._listenFavorites !== undefined){
      this._listenFavorites()//Clean up the listener
    }

  


    this._listenFavorites = firebase.firestore().collection("usuariosClientes").doc(userData.id).collection("favoritos").onSnapshot(snap => {
      let favorites = [];

      snap.forEach(doc => {
        let data = doc.data();
        data.id = doc.id;

        favorites.push(data);
      })

      if(this._mounted){
        this.setState({
          favorites:favorites
        })
      }
    }, e => {
      console.log(e);
      this.addToast("Algo salió mal");
    })
  }

  render(){
    return <div className="app">
      <Toaster className={Classes.OVERLAY} position={Position.TOP} ref={this.refHandlers.toaster}>
                    {/* "Toasted!" will appear here after clicking button. */}
                    {this.state.toasts.map(toast => <Toast action={{onClick:() => {

                    }, text:"Resend"}} {...toast} />)}
            </Toaster>
      <Suspense fallback={<CircleLoader/>}>
        <Router>
          {this.state.modalProduct?<ModalProduct
          userData={this.state.userData}
          favorites={this.state.favorites}
          setQuantity={(index, number) => {
            let data = Object.assign({}, this.state.cart[index]);
            data.cantidad = Number(number);
            
            if(number === ""){
              data.cantidad = 0;
            }
          
           let base = [...this.state.cart];
           base[index] = data;
          
           if(number === ""){
            base.splice(index,1)
          }
          
           if(this._mounted){
            this.setState({
              cart:base
            })
          }
          }}
          addToCart={(e) => {
            let base = [...this.state.cart];
           let data = e;
           data.cantidad = 1;

           base.push(data);

            if(this._mounted){
              this.setState({
                cart:base
              })
            }
          }}
          removeQuantity={i => {
            let data = Object.assign({}, this.state.cart[i]);

            if(data.cantidad > 1){
            data.cantidad = Number(data.cantidad) - 1;
            

           let base = [...this.state.cart];
           base[i] = data;

           if(this._mounted){
            this.setState({
              cart:base
            })
          }
            }else {
              let base = [...this.state.cart];
               base.splice(i,1);

               if(this._mounted){
                this.setState({
                  cart:base
                })
              }
            }

          
          }}
           addQuantity={i => {
             let data = Object.assign({}, this.state.cart[i]);

             data.cantidad = Number(data.cantidad) + 1;

            let base = [...this.state.cart];
            base[i] = data;

            if(this._mounted){
              this.setState({
                cart:base
              })
            }
           }}
           cart={this.state.cart} close={() => {
            if(this._mounted){
              this.setState({
                modalProduct:false,
                productData:null
              })
            }
          }} addToast={this.addToast} data={this.state.productData}/>:null}
          <Navbar

setQuantity={(index, number) => {
  let data = Object.assign({}, this.state.cart[index]);
  data.cantidad = Number(number);
  
  if(number === ""){
    data.cantidad = 0;
  }

 let base = [...this.state.cart];
 base[index] = data;

 if(number === ""){
  base.splice(index,1)
}

 if(this._mounted){
  this.setState({
    cart:base
  })
}
}}

          cleanCart={() => {
            if(this._mounted){
              this.setState({
                cart:[]
              })
            }
          }}
          removeItem={i => {
            let base = [...this.state.cart];

            base.splice(i,1);

            if(this._mounted){
              this.setState({
                cart:base
              })
            }
          }}
           removeQuantity={i => {
              let data = Object.assign({}, this.state.cart[i]);

              if(data.cantidad > 1){
              data.cantidad = Number(data.cantidad) - 1;
              

             let base = [...this.state.cart];
             base[i] = data;

             if(this._mounted){
              this.setState({
                cart:base
              })
            }
              }else {
                let base = [...this.state.cart];
                 base.splice(i,1);

                 if(this._mounted){
                  this.setState({
                    cart:base
                  })
                }
              }

            
            }}
             addQuantity={i => {
               let data = Object.assign({}, this.state.cart[i]);

               data.cantidad = Number(data.cantidad) + 1;

              let base = [...this.state.cart];
              base[i] = data;

              if(this._mounted){
                this.setState({
                  cart:base
                })
              }
             }} cart={this.state.cart} auth={this.state.auth} userData={this.state.userData} addToast={this.addToast}/>
          <Switch>
            <Route exact={true} path="/" render={() =><Redirect to="/inicio"/>}/>
            <Route exact={true} path="/inicio" render={() => <Home 
            setQuantity={(index, number) => {
              let data = Object.assign({}, this.state.cart[index]);
              data.cantidad = Number(number);
              
              if(number === ""){
                data.cantidad = 0;
              }
            
             let base = [...this.state.cart];
             base[index] = data;
            
             if(number === ""){
              base.splice(index,1)
            }
            
             if(this._mounted){
              this.setState({
                cart:base
              })
            }
            }}
            favorites={this.state.favorites} viewProduct={(e) => {
              if(this._mounted){
                this.setState({
                  productData:e,
                  modalProduct:true
                })
              }
            }} cart={this.state.cart}
             
            removeQuantity={i => {
              let data = Object.assign({}, this.state.cart[i]);

              if(data.cantidad > 1){
              data.cantidad = Number(data.cantidad) - 1;
              

             let base = [...this.state.cart];
             base[i] = data;

             if(this._mounted){
              this.setState({
                cart:base
              })
            }
              }else {
                let base = [...this.state.cart];
                 base.splice(i,1);

                 if(this._mounted){
                  this.setState({
                    cart:base
                  })
                }
              }

            
            }}
             addQuantity={i => {
               let data = Object.assign({}, this.state.cart[i]);

               data.cantidad = Number(data.cantidad) + 1;

              let base = [...this.state.cart];
              base[i] = data;

              if(this._mounted){
                this.setState({
                  cart:base
                })
              }
             }}
             addToCart={(e) => {
              let base = [...this.state.cart];
             let data = e;
             data.cantidad = 1;

             base.push(data);

              if(this._mounted){
                this.setState({
                  cart:base
                })
              }
            }} auth={this.state.auth} userData={this.state.userData} addToast={this.addToast}/>}/>
            <Route exact={true} path="/ingreso" render={() => <Auth auth={this.state.auth} userData={this.state.userData} addToast={this.addToast}/>}/>
            <Route exact={true} path="/busqueda/:search" render={({match}) => <SearchModule 
            setQuantity={(index, number) => {
              let data = Object.assign({}, this.state.cart[index]);
              data.cantidad = Number(number);
              
              if(number === ""){
                data.cantidad = 0;
              }
            
             let base = [...this.state.cart];
             base[index] = data;
            
             if(number === ""){
              base.splice(index,1)
            }
            
             if(this._mounted){
              this.setState({
                cart:base
              })
            }
            }}
            favorites={this.state.favorites}
            removeQuantity={i => {
              let data = Object.assign({}, this.state.cart[i]);

              if(data.cantidad > 1){
              data.cantidad = Number(data.cantidad) - 1;
              

             let base = [...this.state.cart];
             base[i] = data;

             if(this._mounted){
              this.setState({
                cart:base
              })
            }
              }else {
                let base = [...this.state.cart];
                 base.splice(i,1);

                 if(this._mounted){
                  this.setState({
                    cart:base
                  })
                }
              }

            
            }}
             addQuantity={i => {
               let data = Object.assign({}, this.state.cart[i]);

               data.cantidad = Number(data.cantidad) + 1;

              let base = [...this.state.cart];
              base[i] = data;

              if(this._mounted){
                this.setState({
                  cart:base
                })
              }
             }}
             addToCart={(e) => {
              let base = [...this.state.cart];
             let data = e;
             data.cantidad = 1;

             base.push(data);

              if(this._mounted){
                this.setState({
                  cart:base
                })
              }
            }}
            viewProduct={(e) => {
              if(this._mounted){
                this.setState({
                  productData:e,
                  modalProduct:true
                })
              }
            }} match={match} addToast={this.addToast} userData={this.state.userData} cart={this.state.cart}/>}/>

            <Route exact={true} path="/favoritos" render={() => <Favorites
            setQuantity={(index, number) => {
              let data = Object.assign({}, this.state.cart[index]);
              data.cantidad = Number(number);
              
              if(number === ""){
                data.cantidad = 0;
              }
            
             let base = [...this.state.cart];
             base[index] = data;
            
             if(number === ""){
              base.splice(index,1)
            }
            
             if(this._mounted){
              this.setState({
                cart:base
              })
            }
            }}
            addToCart={(e) => {
              let base = [...this.state.cart];
             let data = e;
             data.cantidad = 1;

             base.push(data);

              if(this._mounted){
                this.setState({
                  cart:base
                })
              }
            }}
            viewProduct={(e) => {
              if(this._mounted){
                this.setState({
                  productData:e,
                  modalProduct:true
                })
              }
            }}
            favorites={this.state.favorites}
             removeItem={i => {
            let base = [...this.state.cart];

            base.splice(i,1);

            if(this._mounted){
              this.setState({
                cart:base
              })
            }
          }}
           removeQuantity={i => {
              let data = Object.assign({}, this.state.cart[i]);

              if(data.cantidad > 1){
              data.cantidad = Number(data.cantidad) - 1;
              

             let base = [...this.state.cart];
             base[i] = data;

             if(this._mounted){
              this.setState({
                cart:base
              })
            }
              }else {
                let base = [...this.state.cart];
                 base.splice(i,1);

                 if(this._mounted){
                  this.setState({
                    cart:base
                  })
                }
              }

            
            }}
             addQuantity={i => {
               let data = Object.assign({}, this.state.cart[i]);

               data.cantidad = Number(data.cantidad) + 1;

              let base = [...this.state.cart];
              base[i] = data;

              if(this._mounted){
                this.setState({
                  cart:base
                })
              }
             }} cart={this.state.cart} auth={this.state.auth} userData={this.state.userData} addToast={this.addToast}/>}/>


<Route exact={true} path="/productos" render={() => <Products
setQuantity={(index, number) => {
  let data = Object.assign({}, this.state.cart[index]);
  data.cantidad = Number(number);
  
  if(number === ""){
    data.cantidad = 0;
  }

 let base = [...this.state.cart];
 base[index] = data;

 if(number === ""){
  base.splice(index,1)
}

 if(this._mounted){
  this.setState({
    cart:base
  })
}
}}
            addToCart={(e) => {
              let base = [...this.state.cart];
             let data = e;
             data.cantidad = 1;

             base.push(data);

              if(this._mounted){
                this.setState({
                  cart:base
                })
              }
            }}
            viewProduct={(e) => {
              if(this._mounted){
                this.setState({
                  productData:e,
                  modalProduct:true
                })
              }
            }}
            favorites={this.state.favorites}
             removeItem={i => {
            let base = [...this.state.cart];

            base.splice(i,1);

            if(this._mounted){
              this.setState({
                cart:base
              })
            }
          }}
           removeQuantity={i => {
              let data = Object.assign({}, this.state.cart[i]);

              if(data.cantidad > 1){
              data.cantidad = Number(data.cantidad) - 1;
              

             let base = [...this.state.cart];
             base[i] = data;

             if(this._mounted){
              this.setState({
                cart:base
              })
            }
              }else {
                let base = [...this.state.cart];
                 base.splice(i,1);

                 if(this._mounted){
                  this.setState({
                    cart:base
                  })
                }
              }

            
            }}
             addQuantity={i => {
               let data = Object.assign({}, this.state.cart[i]);

               data.cantidad = Number(data.cantidad) + 1;

              let base = [...this.state.cart];
              base[i] = data;

              if(this._mounted){
                this.setState({
                  cart:base
                })
              }
             }} cart={this.state.cart} auth={this.state.auth} userData={this.state.userData} addToast={this.addToast}/>}/>
          </Switch>
        </Router>
      </Suspense>
    </div>
  }
}

export default App;
