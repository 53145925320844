import React from "react";
import { withRouter } from "react-router";

import firebase from "../../utils/firebaseSetUp";

import LoadingFeedback from "../loaders/LoadingFeedback";

import KeywordsGeneration from "../../utils/KeywordsGeneration";

import $ from "jquery";

class Cart extends React.Component {
    _mounted;

    constructor(props){
        super(props);

        this.state = {
            feedback:"",
            iva:true,
            comment:"",
        }
    }

    componentDidMount(){
        this._mounted = true;

        window.document.body.style.overflow = "hidden";


    }

    componentWillUnmount(){
        this._mounted = false;

        window.document.body.style.overflow = "visible";
    }

    render(){
        return <div className="cart">
            {this.state.feedback !== ""?<LoadingFeedback feedback={this.state.feedback}/>:null}
            <div className="backdrop" onClick={() => {
                this.props.close();
            }}></div>

            <div className="cart-content">

            <button className="btn btn-close" onClick={() => {
    this.props.close();
}}>
    <i className="material-icons align-middle">clear</i>
</button>

               {this.props.cart.length > 0?<div className="subtotal">
                    <div className="row mx-0">
                    <div className="col-4 p-1">
                        <h6 className="text-right">Subtotal:</h6>
                    </div>
                    <div className="col-8 p-1">
                        <h6 className="text-center">${(() => {
                            let result = 0;

                            for(let i =0; i < this.props.cart.length ;i ++){
                                result += Number(this.props.cart[i].precioUSD) * Number(this.props.cart[i].cantidad);
                            }

                            return Math.round(result * 100)/100;
                        })()}</h6>
                    </div>
                    </div>


                    <div className="row mx-0">
                    <div className="col-4 p-1 flex-end" onClick={() => {
                        if(this._mounted){
                            this.setState({
                                iva:!this.state.iva
                            })
                        }
                    }}>
                        <h6 className="text-right">Iva</h6>
                        <div className="checkbox ml-2 mr-0 mb-0 mt-0">
  <label className="mb-0"><input type="checkbox" value="" checked={this.state.iva}/></label>
</div>
                    </div>

                    <div className="col-8 p-1">
                        {this.state.iva?<h6 className="text-center">${(() => {
                            let result = 0;

                            for(let i =0; i < this.props.cart.length ;i ++){
                                result += Number(this.props.cart[i].precioUSD) * Number(this.props.cart[i].cantidad);
                            }

                            return Math.round((result*0.16) * 100)/100;
                        })()}</h6>:null}
                    </div>
                   
                    </div>

                    <div className="row mx-0">
                    <div className="col-4 p-1">
                        <h6 className="text-right">Total:</h6>
                    </div>
                    <div className="col-8 p-1">
                        <h6 className="text-center">${(() => {
                            let result = 0;

                            for(let i =0; i < this.props.cart.length ;i ++){
                                result += Number(this.props.cart[i].precioUSD) * Number(this.props.cart[i].cantidad);
                            }

                            return Math.round((result + (this.state.iva?result*0.16:0)) * 100)/100;
                        })()}</h6>
                    </div>
                    </div>

                   

                    <button className="btn btn-danger btn-block" onClick={() =>{
                        if(this._mounted){
                            this.setState({
                                feedback:"Enviando orden..."
                            })
                        }

                        let products = [...this.props.cart];

                        for(let i=0; i < products.length; i++){
                            products[i] = {
                                cantidad:products[i].cantidad,
                                codigo:products[i].codigo,
                                idProducto:products[i].id,
                                marca:products[i].marca,
                                nombre:products[i].nombre,
                                precioUSD:Math.round((Number(products[i].precioUSD) * Number(products[i].cantidad))*100)/100,
                                precioUnitario:Math.round(Number(products[i].precioUSD)*100)/100,
                                referencia:"Sin definir"
                            }
                        }

                        firebase.firestore().collection("pedidosClientes").add({
                            cantidadProductos:this.props.cart.length,
                            comentario:String(this.state.comment).trim(),
                            contieneIva:this.state.iva,
                            correoCliente:this.props.userData.email,
                            creado:new Date(),
                            estadoOrden:"pendiente",
                            idUsuario:this.props.userData.id,
                            ivaAplicado:0.16,
                            nombreCliente:this.props.userData.username,
                            keywords:KeywordsGeneration.generateKeywords(this.props.userData.email).concat(KeywordsGeneration.generateKeywords(this.props.userData.username)),
                            productos:products,
                            subTotal:(() => {
                                let result = 0;
    
                                for(let i =0; i < this.props.cart.length ;i ++){
                                    result += Number(this.props.cart[i].precioUSD) * Number(this.props.cart[i].cantidad);
                                }
    
                                return Math.round((result) * 100)/100;
                            })(),
                            subTotalBSS:0,
                            tasaDeCambio:0,
                            total:(() => {
                                let result = 0;
    
                                for(let i =0; i < this.props.cart.length ;i ++){
                                    result += Number(this.props.cart[i].precioUSD) * Number(this.props.cart[i].cantidad);
                                }
    
                                return Math.round((result + (this.state.iva?result*0.16:0)) * 100)/100;
                            })(),
                            totalBSS:0
                        })
                        .then((o) => {
                            this.props.addToast("Orden enviada!");
                            this.props.cleanCart();

                            if(this._mounted){
                                this.setState({
                                    feedback:""
                                })
                            }

                            $.ajax({url:" https://sanifer-ventas.herokuapp.com/sendEmail", 
                                     
                                        data:{
                                            id:o.id,
                                            email:firebase.auth().currentUser.email,
                                            type:"orden"
                                          },
                                   method:"POST",
                                    success:(response) =>{
                                        if(this._mounted){
                                            this.setState({
                                             //   isLoading:false,
                                                feedback:""
                                            })
                                        }

                                     //   this.props.addToast("Usuario creado!");
                                       // this.props.close();
                                       // this.props.reload();

                                       console.log("Success");
                                          }, error:async (err) => {
                                            if(this._mounted){
                                                this.setState({
                                                  
                                                    feedback:""
                                                })
                                            }

                                        
                                        
                                            
                                      }})

                            this.props.close();
                        })
                        .catch(e => {
                            console.log(e);
                            this.props.addToast("Algo salió mal");

                            if(this._mounted){
                                this.setState({
                                    feedback:""
                                })
                            }
                        })
                    }}>Enviar Orden</button>
                </div>:null}

                {this.props.cart.length > 0?<div className="mt-3 form-group">
                    <label>Comentarios</label>
                    <textarea className="form-control" value={this.state.comment} onChange={e => {
                        if(this._mounted){
                            this.setState({
                                comment:e.currentTarget.value
                            })
                        }
                    }}></textarea>
                </div>:null}

                {this.props.cart.length < 1?<p className="text-center mt-4">No hay elementos en el carrito</p>:
                this.props.cart.map((e,i) => {
                    return <div className="cart-item shadow-sm  mt-4" key={i}>

                        <button className="btn btn-sm close" onClick={() => {
                            this.props.removeItem(i);
                        }}>
                            <i className="material-icons align-middle">clear</i>
                        </button>

                        <div className="row mx-0">
                        <div className="col-4">
                            <div className="image" style={{
                                backgroundImage:`url(${e.imagenUrl})`,
                                backgroundPosition:"center",
                                backgroundRepeat:"no-repeat",
                                backgroundSize:"contain"
                            }}></div>
                        </div>

                        <div className="col-8">
                        <div className="properties mt-4">
                    <div className="row mx-0 property-item">
                        <div className="col-4 p-1">
                            <p className="text-right mb-0">Código:</p>
                        </div>

                        <div className="col-8 p-1">
                            <p className="text-center mb-0">{e.codigo}</p>
                        </div>
                    </div>

          

                    <div className="row mx-0 property-item mt-2">
                        <div className="col-4 p-1">
                            <p className="text-right mb-0">Marca:</p>
                        </div>

                        <div className="col-8 p-1">
                            <p className="text-center mb-0 ">{e.marca}</p>
                        </div>
                    </div>

                    <div className="row mx-0 property-item mt-2">
                        <div className="col-4 p-1">
                            <p className="text-right mb-0">Empaque</p>
                        </div>

                        <div className="col-8 p-1">
                            <p className="text-center mb-0">{e.cantEmp}</p>
                        </div>
                    </div>

                    <div className="row mx-0 property-item mt-2">
                        <div className="col-4 p-1">
                            <p className="text-right mb-0">Precio</p>
                        </div>

                        <div className="col-8 p-1">
                            <p className="text-center mb-0">{e.precioUSD}</p>
                        </div>
                    </div>
                </div>
                        </div>
                        </div>

                        <div className="flex-sb mt-2 quantity-selector">
                            <h5 className="mb-0">${Math.round((Number(e.precioUSD) * Number(e.cantidad))*100)/100}</h5>

                            <div className="flex-end">
                                <button className="btn" onClick={() => {
                                     this.props.removeQuantity(i)
                                }}>
                                    <i className="material-icons align-middle">remove</i>
                                </button>
                                <input className="form-control" type="number" onChange={e => {
                                    this.props.setQuantity(i, e.currentTarget.value)
                                }} style={{textAlign:"center"}} value={e.cantidad}/>
                                <button className="btn" onClick={() => {
                                    this.props.addQuantity(i)
                                }}>
                                    <i className="material-icons align-middle">add</i>
                                </button>
                            </div>
                        </div>
                    </div>
                })}

                
            </div>

        </div>
    }
}

export default withRouter(Cart);