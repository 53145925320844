import React from "react";
import { withRouter } from "react-router";

import fireabse from "../../utils/firebaseSetUp";

class Menu extends React.Component {
    _mounted;

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }


    render(){
        return <div className="menu">

            <div className="backdrop" onClick={() => {
                this.props.close();
            }}></div>

            <div className="menu-content">
               

                <div className="header">
                    <h5 className="text-center mb-0" style={{
                        color:"white"
                    }}>{this.props.auth === false?"":fireabse.auth().currentUser.email}</h5>

<button className="btn btn-close" onClick={() => {
    this.props.close();
}}>
                    <i className="material-icons align-middle">clear</i>
                </button>
                </div>

                <div className="body">

            {this.props.auth === false?<div className="flex-left session-block">
                 <button className="btn btn-danger" onClick={() => {
                     this.props.history.push("/ingreso");
                     this.props.close();
                 }}>
                     INICIAR SESIÓN
                 </button>
            </div>:null}
            <div className="menu-item mt-2" onClick={() => {
                this.props.history.push("/inicio");
                this.props.close();
            }}>
                <p className="mb-0">Inicio</p>
            </div>
            {this.props.auth?<div className="menu-item" onClick={() => {
                this.props.history.push("/favoritos");
                this.props.close()
            }}>
                <p className="mb-0">Favoritos</p>
            </div>:null}
            <div className="menu-item" onClick={() => {
                this.props.history.push("/productos");
                this.props.close()
            }}>
                <p className="mb-0">Productos</p>
            </div>


            {this.props.auth === true?<div className="menu-item" onClick={() => {
            fireabse.auth().signOut()
            .then(() => {
                this.props.close();
            })
            .catch(e => {
                console.log(e);
                this.props.addToast("Algo salió mal")
            })
            }}>
                <p className="mb-0">Salir</p>
            </div>:null}

            </div>
            </div>
        </div>
    }
}

export default withRouter(Menu);