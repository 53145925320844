import React from "react";
import { withRouter } from "react-router";

import firebase from "../../utils/firebaseSetUp";

class ModalProduct extends React.Component {
    _mounted;

    constructor(props){
        super(props);

        this.state = {
            favoritesID:[]
        }
    }

    componentDidMount(){
        this._mounted = true;

        window.document.body.style.overflow = "hidden";

        let favoritesID = [];

        for(let i =0; i < this.props.favorites.length; i++){
            favoritesID.push(this.props.favorites[i].id)
        }

        if(this._mounted){
            this.setState({
                favoritesID:favoritesID
            })
        }
    }


    componentWillReceiveProps(nextProps){
        if(this.props.favorites.length !== nextProps.favorites.length){
            let favoritesID = [];

        for(let i =0; i < nextProps.favorites.length; i++){
            favoritesID.push(nextProps.favorites[i].id)
        }

        if(this._mounted){
            this.setState({
                favoritesID:favoritesID
            })
        }
        }
    }

    componentWillUnmount(){
        this._mounted = false;

        window.document.body.style.overflow = "visible";
    }

    render(){
        return <div className="modal-wrapper fade-in">
            <div className="backdrop" onClick={() => {
                this.props.close();
            }}></div>

            <div className="modal-content">
                <button className="btn close" onClick={() => {
                    this.props.close()
                }}>
                    <i className="material-icons align-middle">clear</i>
                </button>

                <h4 className="text-center mt-5">{this.props.data.nombre}</h4>

                <div className="flex-center">
                    <div className="image" style={{
                        backgroundImage:`url(${this.props.data.imagenUrl})`,
                        backgroundPosition:"center",
                        backgroundRepeat:"no-repeat",
                        backgroundSize:"contain"
                    }}></div>
                </div>


                <div className="properties mt-4">
                    <div className="row mx-0 property-item">
                        <div className="col-4 p-2">
                            <p className="text-right mb-0">Código:</p>
                        </div>

                        <div className="col-8 p-2">
                            <p className="text-center mb-0">{this.props.data.codigo}</p>
                        </div>
                    </div>

                    <div className="row mx-0 property-item mt-2">
                        <div className="col-4 p-2">
                            <p className="text-right mb-0">Referencia:</p>
                        </div>

                        <div className="col-8 p-2">
                            <p className="text-center mb-0">Sin definir</p>
                        </div>
                    </div>

                    <div className="row mx-0 property-item mt-2">
                        <div className="col-4 p-2">
                            <p className="text-right mb-0">Marca:</p>
                        </div>

                        <div className="col-8 p-2">
                            <p className="text-center mb-0 ">{this.props.data.marca}</p>
                        </div>
                    </div>

                    <div className="row mx-0 property-item mt-2">
                        <div className="col-4 p-2">
                            <p className="text-right mb-0">Empaque</p>
                        </div>

                        <div className="col-8 p-2">
                            <p className="text-center mb-0">{this.props.data.cantEmp}</p>
                        </div>
                    </div>
                </div>

                <div className="flex-end mt-2">


                {this.props.userData !== null?this.state.favoritesID.includes(this.props.data.id) || this.props.data.favorite === true?<button className="btn btn-sm favorite" onClick={() => {
               

                let data =Object.assign({}, this.props.data);
                data.id = this.props.data.id
                
                firebase.firestore().collection("usuariosClientes").doc(this.props.userData.id).collection("favoritos").doc(this.props.data.id).delete()
                .then(() => {
                   // this.props.addToast("")
                })
                .catch(e => {
                   

                this.props.addToast("Algo salió mal")
                })
            }}><i className="material-icons align-middle">favorite</i></button>:<button className="btn btn-sm favorite" onClick={() => {
                     

                        let data =Object.assign({}, this.props.data);
                        data.id = this.props.data.id
                        
                        firebase.firestore().collection("usuariosClientes").doc(this.props.userData.id).collection("favoritos").doc(this.props.data.id).set(data)
                        .then(() => {
                            this.props.addToast("Agregado a favoritos!")
                        })
                        .catch(e => {
                            

                        this.props.addToast("Algo salió mal")
                        })
                    }}>
                        <i className="material-icons align-middle">favorite_border</i>
                    </button>:null}

                    {(() => {

let flag = 0;

let newIndex;

for(let i =0; i < this.props.cart.length; i++){
    if(this.props.cart[i].id === this.props.data.id){
        flag = 1;
        newIndex = i
    }
}

if(flag === 1){
    return <div className="add-to-cart flex-end">
        <button className="btn btn-sm" onClick={() => {
            this.props.removeQuantity(newIndex)
        }}>
            <i className="material-icons align-middle">remove</i>
        </button>
        <input className="form-control" type="number" onChange={e => {
            this.props.setQuantity(newIndex, e.currentTarget.value)
        }} style={{maxWidth:"60px", textAlign:"center"}} value={this.props.cart[newIndex].cantidad}/>
        <button className="btn btn-sm"><i className="material-icons align-middle" onClick={() => {
            this.props.addQuantity(newIndex)
        }}>add</i></button>
    </div>
}else {


 return <button className="btn btn-sm add-to-cart" onClick={() => {
        this.props.addToCart(this.props.data)
        }}>
<i className="material-icons align-middle">add_shopping_cart</i>
</button>
}
})()}
                </div>

            </div>
        </div>
    }
}

export default withRouter(ModalProduct);